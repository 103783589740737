/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .btnFormSmall {
    width: 100%;
    margin-top: 1rem;
    margin-right: 1rem;
  }

  .dateDashboard {
    flex-wrap: wrap;
  }

  .chartDashboard {
    height: 260px;
    width: 700px;
    margin-top: 150px;
  }

  .saleOrderSum {
    flex-wrap: wrap;
  }

}

/* Small devices (portrait tablets and large phones, 600px and up) */
/* @media only screen and (min-width: 600px) {...} */

/* Medium devices (landscape tablets, 768px and up) */
@media screen and (min-device-width: 768px) and (max-device-width: 1024px) {
  .btnForm {
    width: auto;
    margin-top: 1rem;
    margin-right: 1rem;
  }

  .dateDashboard {
    flex-wrap: wrap;
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  .chartDashboard {
    height: 260px;
    width: 700px;
    margin-top: 150px;
  }

  .inputArea {
    width: 225px;
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  .chartDashboard {
    height: 250px;
    width: 480px;
    position: absolute;
    margin-top: 150px;
  }

}

@media only screen and (min-width: 1440px) {
  .chartDashboard {
    height: 250px;
    width: 560px;
    position: absolute;
    margin-top: 150px;
  }

  .inputArea {
    width: 290px;
  }
}

@media only screen and (min-width: 1450px) {
  .chartDashboard {
    height: 260px;
    width: 700px;
    position: absolute;
    margin-top: 100px;
  }

  .inputArea {
    width: 450px;
  }
}

.saleOrderTotal {
  display: flex;
  margin-right: 1rem;
}

.dateDashboard {
  flex-wrap: wrap;
}

.btnForm {
  width: max-content;
  margin-top: 1rem;
  margin-right: 1rem;
}

.btnFormSmall {
  width: 100%;
  margin-top: 1rem;
  margin-right: 1rem;
}

.titleChart {
  text-align: 'center';
  font-size: 16px;
  font-weight: bold;
  margin-top: 0.5rem;
}

.search {
  display: flex;
}

.fitWidth {
  max-width: max-content;
}

.flexWrap1 {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.flexWrap {
  display: flex;
  flex-wrap: wrap;
}

.flexCenter {
  align-items: center !important;
  justify-content: center !important;
  font-size: 13px;
  display: flex !important;
}

.flexCenter1 {
  align-items: center;
  display: flex;
  justify-content: center;
}

.flexWrapMargin {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 0.3rem;
  margin-top: -0.6rem;
}

.red {
  color: red;
}

.underline {
  text-decoration: underline;
}

.underline:hover {
  color: #e2211b;
  cursor: pointer;
}

.link:hover {
  cursor: pointer;

}

.underLink {
  text-decoration: underline;
  color: #20a8d8;
}

.underLink:hover {
  cursor: pointer;
  color: red;
}

.underlineLink {
  text-decoration: underline;
}

.underlineLink:hover {
  color: #2A79B0;
  cursor: pointer;
}

.link {
  text-decoration: underline;
  color: black;
}

.link:hover {
  cursor: pointer;
  color: #2A79B0;
}

.textHeader {
  color: #797979;
  text-align: center;
}

.textBoldHeader {
  color: black;
  font-weight: bold;
}

.texColortHeader {
  color: #797979;
}

.colorYellow2 {
  color: #C97C18;
  margin: 1rem;
}

.colorTextItem {
  color: #797979;
}

.formTextHeader {
  color: #797979;
  font-weight: bold;
}

.boldGrayText {
  font-weight: bold;
  color: #5c6873c7;
}

.backgroundItem {
  background-color: #EAFBDB;
}

.backgroundHeader {
  background-color: #f9f9f9;
}

.backgroundLoading {
  color: red;
}

.pagelist_listext1 {
  color: #C13F0B;
}

.backgroundInput {
  background-color: #ffffff;
  color: black;
  border: 1px solid #d9d9d9;
  border-top-left-radius: 6px !important;
  border-bottom-left-radius: 6px !important;
  border-top-right-radius: 6px !important;
  border-bottom-right-radius: 6px !important;
  transition-duration: 0.2s !important;
}

.customInputMask {
  flex: 1 1 auto !important;
}

.customInputMask>div {
  padding: 2px 12px !important;
}

.ant-select-multiple .ant-select-selection-item-remove {
  display: flex !important;
  align-items: center !important;
}

input.customInputMask {
  flex: 1 1 auto !important;
  margin-right: 0.5rem;
  height: 35px !important;
  padding: 0.375rem 0.75rem;
  width: 0;
}

.backgroundInput:hover {
  border: 1px solid #4a99da;
}

.backgroundInput:focus {
  border: 1px solid #4a99da !important;
}

.backgroundInputCenter {
  background-color: #FFFEF4;
  color: black;
  text-align-last: center;
  -moz-text-align-last: center;
  -ms-text-align-last: center;
  border-radius: 25rem !important;
}

.backgroundInput1 {
  background-color: #FFFEF4;
  width: 10rem;
}

.pagelist_listext5 {
  font-size: 15px;
  color: #337ab7;
  font-weight: bold;
  margin-left: 1rem;
}

.pageform_formgeninfo {
  color: #C59F4D;
  font-weight: bold;
}

.backgroundSum {
  background-color: #eaf0f7;
}

.backgroundText {
  background-color: #eff6ff;
}

.colorButton {
  background-color: #358fff;
  color: #fff;
  border-color: #001938;
}

.formButton {
  background-color: #FEF2A9;
  color: #77562B;
  border-color: #77562B;
  width: 5rem !important;
  justify-content: center;
  font-size: 0.7rem;
}

.formButtonSmall {
  background-color: #FEF2A9;
  color: #77562B;
  border-color: #77562B;
  width: max-content !important;
  justify-content: center;
  font-size: 0.7rem;
}

.colorInput {
  background-color: #FFEEEE;
  color: #C10000;
}

.colorText {
  color: #B50000;
  font-weight: bold;
}

.colorTextRed {
  color: #B50000;
}

.colorTextHeader {
  color: #C10000;
}

.colorTextDate {
  color: #d9524f;
}

.colorTextSoldPrice {
  color: #28a745;
}

.backgroundTabHeader {
  background-color: #fff9d4;
}

.colorItemInput {
  background-color: #feffdd;
}

.bgSelectCompany {
  width: auto;
  background-color: #FFEEEE;
  color: #C10000;
}

.colorTextBold {
  color: #C10000;
  font-weight: bold;
}

.textBold {
  font-weight: bold;
}

.colorItem {
  color: #E06400;
}

.colorLinkText {
  color: #2A79B0;
}

.colorLinkTextBold {
  color: #2A79B0;
  font-weight: bold;
}

.selectOption {
  width: 100%
}

.colorTabHeader {
  background-color: #f1f1f1;
}

.colorHeader {
  background-color: #e7e7e7;
}

.colorHeaderText {
  text-decoration: underline;
}

.formDisabled {
  background-color: #aeafb1;
  opacity: 1;
}

input::disabled {
  background-color: red;
}

.colorError {
  color: red;
  font-size: small;
  font-style: italic;
}

/* Color of Button */

.colorModal {
  background-color: #f0f3f5;
}

.colorHeaderFilter {
  background-color: #d6d6d6;
}

.btnColorDelete:hover {
  background-color: #f66c6b;
  color: white;
  border: 1px solid #690505;
}

.btnColorDelete {
  background-color: white;
  color: #690505;
  border: 1px solid #690505;
}

.btnColorOpen:hover {
  background-color: #700f0e;
  color: white;
  border: 1px solid #350202;
}

.btnColorOpen {
  background-color: white;
  color: #350202;
  border: 1px solid #350202;
  border-radius: 8px;
}

.btnColorSave {
  background-color: white;
  color: #422a0b;
  border: 1px solid #77562B;
}

.btnColorSave:hover {
  background-color: white;
  color: #422a0b;
  border: 1px solid #77562B;
}

.btnColorCancel {
  background-color: white;
  color: #2d2d2d;
  border: 1px solid #5a5a5a;
}

.btnColorCancel:hover {
  background-color: #949494;
  color: white;
  border: 1px solid #5a5a5a;
}

.btnColorAdd:hover {
  background-color: #2d9437;
  color: white;
  border: 1px solid #135f1f;
}

.btnColorAdd {
  background-color: white;
  color: #043515;
  border: 1px solid #135f1f;
}

.btnColorEdit:hover {
  background-color: #6fadca;
  color: white;
  border: 1px solid #447e98;
  font-size: 0.8rem;
}

.btnColorEdit {
  background-color: white;
  color: #226584;
  border: 1px solid #447e98;
  font-size: 0.8rem;
}

.btnColorSearch:hover {
  background-color: #2e6da4;
  color: white;
  border: 1px solid #2e6da4;
}

.btnColorSearch {
  background-color: #337ab7;
  color: white;
  border: 1px solid #337ab7;
}

.btnColorVerity:hover {
  background-color: #f66303;
  color: white;
  border: 1px solid #7d3200;
}

.btnColorVerity {
  background-color: white;
  color: #4c1f01;
  border: 1px solid #7d3200;
}

.btnColorInformation:hover {
  background-color: white;
  color: #337ab7;
  border: 1px solid #2e6da4;
}

.btnColorInformation {
  background-color: #337ab7;
  color: white;
  border: 1px solid #2e6da4;
}


/* Color of Button Small*/

.btnColorDeleteSmall:hover {
  background-color: #f66c6b;
  color: white;
  border: 1px solid #690505;
  font-size: 0.8rem;
}

.btnColorDeleteSmall {
  background-color: white;
  color: #690505;
  border: 1px solid #690505;
  font-size: 0.8rem;
}

.btnColorOpenSmall:hover {
  background-color: #700f0e;
  color: white;
  border: 1px solid #350202;
  font-size: 0.8rem;
}

.btnColorOpenSmall {
  background-color: white;
  color: #350202;
  border: 1px solid #350202;
  font-size: 0.8rem;
}

.btnColorSaveSmall {
  background-color: white;
  color: #422a0b;
  border: 1px solid #77562B;
  font-size: 0.8rem;
}

.btnColorSaveSmall:hover {
  background-color: #FEF2A9;
  color: #422a0b;
  border: 1px solid #77562B;
  font-size: 0.8rem;
}

.btnColorCancelSmall {
  background-color: white;
  color: #2d2d2d;
  border: 1px solid #5a5a5a;
  font-size: 0.8rem;
}

.btnColorCancelSmall:hover {
  background-color: #949494;
  color: white;
  border: 1px solid #5a5a5a;
  font-size: 0.8rem;
}

.btnColorAddSmall:hover {
  background-color: #2d9437;
  /* color: #043515;  */
  color: white;
  border: 1px solid #135f1f;
  font-size: 0.8rem;
}

.btnColorAddSmall {
  background-color: white;
  color: #043515;
  border: 1px solid #135f1f;
  font-size: 0.8rem;
}

.btnColorFormAddSmall:hover {
  background-color: #2d9437;
  /* color: #043515;  */
  color: white;
  border: 1px solid #135f1f;
  font-size: 0.7rem;
}

.btnColorFormAddSmall {
  background-color: white;
  color: #043515;
  border: 1px solid #135f1f;
  font-size: 0.7rem;
}

.btnColorEditSmall:hover {
  background-color: #6fadca;
  color: white;
  border: 1px solid #447e98;
  font-size: 0.8rem;
}

.btnColorEditSmall {
  background-color: white;
  color: #226584;
  border: 1px solid #447e98;
  font-size: 0.8rem;
}

.btnColorSearchSmall:hover {
  background-color: #2e6da4;
  color: white;
  border: 1px solid #2e6da4;
  font-size: 0.8rem;
}

.btnColorSearchSmall {
  background-color: #337ab7;
  color: white;
  border: 1px solid #337ab7;
  font-size: 0.8rem;
}

.btnColorVeritySmall:hover {
  background-color: #f66303;
  color: white;
  border: 1px solid #7d3200;
  font-size: 0.8rem;
}

.btnColorVeritySmall {
  background-color: white;
  color: #4c1f01;
  border: 1px solid #7d3200;
  font-size: 0.8rem;
}

/* Color of Button Small And Form*/

.btnFormColorDelete:hover {
  background-color: red;
  color: white;
}

.btnFormColorDelete {
  color: white;
  transition-duration: 0.3s;
  border-radius: 8px;
  background-color: #690505;
}

.btnFormColorOpenSmall:hover {
  background-color: #700f0e;
  color: white;
  border: 2px solid #350202;
  font-size: 0.7rem;
  width: 3rem;
}

.btnFormColorOpenSmall {
  background-color: white;
  color: #350202;
  border: 2px solid #350202;
  font-size: 0.7rem;
  width: 3rem;
}

.btnFormColorSaveSmall {
  background-color: white;
  color: #422a0b;
  border: 2px solid #77562B;
  font-size: 0.7rem;
  width: 3rem;
}

.btnFormColorSaveSmall:hover {
  background-color: #FEF2A9;
  color: #422a0b;
  border: 2px solid #77562B;
  font-size: 0.7rem;
  width: 3rem;
}

.btnFormColorCancelSmall {
  background-color: white;
  color: #2d2d2d;
  border: 2px solid #5a5a5a;
  font-size: 0.7rem;
  width: 3rem;
}

.btnFormColorCancelSmall:hover {
  background-color: #949494;
  color: white;
  border: 2px solid #5a5a5a;
  font-size: 0.7rem;
  width: 3rem;
}

.btnFormColorAddSmall:hover {
  background-color: #2d9437;
  color: white;
  border: 2px solid #135f1f;
  font-size: 0.7rem;
  width: 3rem;
}

.btnFormColorAddSmall {
  background-color: white;
  color: #043515;
  border: 2px solid #135f1f;
  font-size: 0.7rem;
  width: 3rem;
}

.btnFormColorAddSmall1:hover {
  background-color: #2d9437;
  color: white;
  border: 2px solid #135f1f;
  font-size: 0.7rem;
}

.btnFormColorAddSmall1 {
  background-color: white;
  color: #043515;
  border: 2px solid #135f1f;
  font-size: 0.7rem;
}

.btnFormColorEditSmall:hover {
  background-color: #6fadca;
  color: white;
  border: 2px solid #447e98;
  font-size: 0.7rem;
  width: 3rem;
}

.btnFormColorEditSmall {
  background-color: white;
  color: #226584;
  border: 2px solid #447e98;
  font-size: 0.7rem;
  width: 3rem;
}

.btnFormColorSearchSmall:hover {
  background-color: #4d89d2;
  color: white;
  border: 2px solid #194882;
  font-size: 0.7rem;
  width: 3rem;
}

.btnFormColorSearchSmall {
  background-color: white;
  color: #143a69;
  border: 2px solid #194882;
  font-size: 0.7rem;
  width: 3rem;
}

.btnFormColorOpenSmall:hover {
  background-color: #700f0e;
  color: white;
  border: 2px solid #350202;
  font-size: 0.7rem;
  width: 3rem;
}

.btnFormColorOpenSmall {
  background-color: white;
  color: #350202;
  border: 2px solid #350202;
  font-size: 0.7rem;
  width: 3rem;
}

.btnFormColorVeritySmall:hover {
  background-color: #f66303;
  color: white;
  border: 2px solid #7d3200;
  font-size: 0.7rem;
  width: 3rem;
}

.btnFormColorVeritySmall {
  background-color: white;
  color: #4c1f01;
  border: 2px solid #7d3200;
  font-size: 0.7rem;
  width: 3rem;
}

.formSizeButton {
  line-height: normal;
}

.btnDropdownItem {
  background-color: #f86c6b;
  color: #ffff;
  font-size: 0.8rem;
}

/* Color Button Bootstrap 4 */

.btnColorMain {
  background-color: #0182c6;
  color: white;
  border-radius: 8px;
  border: none;
  /* border: 1px solid #337ab7; */
}

.btnColorMain:hover {
  background-color: rgb(44, 91, 187);
  color: white;
  border-radius: 8px;
  border: none;
  /* border: 1px solid #2e6da4; */
}

.btnColorMainSmall {
  background-color: #3671e4;
  color: white;
  border: 1px solid #3671e4;
  font-size: 0.8rem;
}

.btnColorMainSmall:hover {
  background-color: #3671e4;
  color: white;
  border: 1px solid #3671e4;
  font-size: 0.8rem;
}

.btnFormColorMainSmall {
  background-color: #337ab7;
  color: white;
  border: 2px solid #337ab7;
  font-size: 0.7rem;
  width: 3rem;
}

.btnFormColorMainSmall:hover {
  background-color: #2e6da4;
  color: white;
  border: 2px solid #2e6da4;
  font-size: 0.7rem;
  width: 3rem;
}

.btnColorCancelMain {
  background-color: #dddddd;
  color: black;
  border: 1px solid #dddddd;
  /* 2e6da4 */
}

.btnColorCancelMain:hover {
  background-color: #dddddd;
  color: #919291;
  border: 1px solid #dddddd;
}

.btnColorCancelMainSmall {
  background-color: #dddddd;
  color: black;
  border: 1px solid #dddddd;
  font-size: 0.8rem;
}

.btnColorCancelMainSmall:hover {
  background-color: #dddddd;
  color: #919291;
  border: 1px solid #dddddd;
  font-size: 0.8rem;
}

.btnColorEditMain {
  background-color: #f0ad4e;
  color: white;
  border: 1px solid #f0ad4e;
}

.btnColorEditMain:hover {
  background-color: #eea236;
  color: white;
  border: 1px solid #eea236;
}

.btnColorEditMainSmall {
  background-color: #f0ad4e;
  color: white;
  border: 1px solid #f0ad4e;
  font-size: 0.8rem;
}

.btnColorEditMainSmall:hover {
  background-color: #eea236;
  color: white;
  border: 1px solid #eea236;
  font-size: 0.8rem;
}

.btnColorAddMain:hover {
  background-color: #46b8da;
  color: white;
  border: 1px solid #46b8da;
}

.btnColorAddMain {
  background-color: #5bc0de;
  color: white;
  border: 1px solid #5bc0de;
}

.btnColorAddMainSmall:hover {
  background-color: #46b8da;
  color: white;
  border: 1px solid #46b8da;
}

.btnColorAddMainSmall {
  background-color: #5bc0de;
  color: white;
  border: 1px solid #5bc0de;
}

/* CSS width */

.inputText14 {
  width: 3rem !important;
}

.inputText31 {
  width: 3.3rem !important;
}

.inputText34 {
  width: 3.5rem !important;
}

.inputText8 {
  width: 4rem !important;
}

.inputFromText {
  width: 4rem !important;
  justify-content: center;
}

.inputFromText1 {
  width: 5rem !important;
  justify-content: center;
}

.inputTextForm2 {
  width: 4.15rem !important;
}

.inputTextForm3 {
  width: 4.5rem !important;
}

.inputText23 {
  width: 4.7rem !important;
}

.inputTextForm {
  width: 5rem !important;
}

.inputText33 {
  width: 5.4rem !important;
}

.inputTextForm4 {
  width: 5.5rem !important;
}

.inputTextForm7 {
  width: 5.8rem !important;
}

.inputTextForm1 {
  width: 6rem !important;
}

.inputTextForm5 {
  width: 6.1rem !important;
}

.inputTextForm6 {
  width: 6.3rem !important;
}

.inputText36 {
  width: 6.5rem !important;
}

.inputText46 {
  width: 6.6rem !important;
  justify-content: center !important;
}

.inputText43 {
  width: 6.7rem !important;
}

.inputText35 {
  width: 7rem !important;
}

.inputFromText2 {
  width: 7rem !important;
  justify-content: center;
}

.inputText9 {
  width: 7.5rem !important;
}

.inputText39 {
  width: 7.8rem !important;
}

.inputText5 {
  width: 8rem !important;
}

.inputText38 {
  width: 8.2rem !important;
}

.inputText18 {
  width: 8.5rem !important;
}

.inputText45 {
  width: 8.7rem !important;
}

.inputText32 {
  width: 8.8rem !important;
}

.inputText2 {
  width: 9rem !important;
}

.inputText42 {
  width: 9.3rem !important;
}

.inputText29 {
  width: 9.5rem !important;
}

.inputText4 {
  width: 10rem !important;
}

.inputText41 {
  width: 10.3rem !important;
}

.inputText7 {
  width: 10.5rem !important;
}

.inputText12 {
  width: 11rem !important;
}

.inputText40 {
  width: 11.3rem !important;
}

.inputText25 {
  width: 11.5rem !important;
}

.inputText37 {
  width: 11.8rem !important;
}

.inputText11 {
  width: 12rem !important;
}

.w12rem {
  width: 12rem !important;
}

.inputText21 {
  width: 12.5rem !important;
}

.inputText {
  width: 13rem !important;
}

.inputText20 {
  width: 13.5rem !important;
}

.inputText24 {
  width: 14.2rem !important;
}

.inputText3 {
  width: 15rem !important;
}

.inputText6 {
  width: 15.7rem !important;
}

.inputText44 {
  width: 16rem !important;
}

.inputText28 {
  width: 16.5rem !important;
}

.inputText15 {
  width: 17rem !important;
}

.inputText27 {
  width: 17.5rem !important;
}

.inputText22 {
  width: 18.5rem !important;
}

.inputText19 {
  width: 19.2rem !important;
}

.inputText1 {
  width: 20rem !important;
}

.inputText17 {
  width: 20.5rem !important;
}

.inputText16 {
  width: 21rem !important;
}

.inputText10 {
  width: 22rem !important;
}

.inputText30 {
  width: 22.5rem !important;
}

.inputText13 {
  width: 25rem !important;
}

.inputText26 {
  width: 30rem !important;
}

.w150 {
  width: 150px;
}

.w110 {
  width: 110px;
}

.wDocStatus {
  width: 200px;
}

.wDoc {
  width: 250px;
}

.wOrder {
  width: 300px;
}

.wCustomerInfo {
  width: 300px;
}

.wDocOrder {
  width: 500px;
}

.marginrem {
  margin-bottom: 0.2rem;
  margin-top: -0.2rem;
}

.mBottomTop02rem {
  margin-bottom: 0.6rem;
  margin-top: 0.5rem;
}

.mBottomToprem {
  margin-top: -1.2rem;
  margin-bottom: -2rem;
}

.margin03rem {
  margin-bottom: -0.3rem;
  margin-top: 0.3rem
}

.contentText {
  justify-content: center;
}

.alignText {
  text-align: center;
}


/* formFormat */

.formFormat17 {
  text-align: right;
  width: 4rem;
  margin-right: 0.5rem;
}

.formFormat22 {
  text-align: right;
  width: 5rem;
  margin-right: 0.5rem;
}

.formFormat19 {
  text-align: right;
  width: 5.2rem;
  margin-right: 0.5rem;
}

.formFormat3 {
  text-align: right;
  width: 5.5rem;
  margin-right: 0.5rem;
}

.formFormat2 {
  text-align: right;
  width: 6rem;
  margin-right: 0.5rem;
}

.formFormat4 {
  text-align: right;
  width: 6.5rem;
  margin-right: 0.5rem;
  font-size: 13px;
}

.formFormat14 {
  width: 6.5rem;
  margin-right: 0.5rem;
}

.formFormat1 {
  text-align: right;
  width: 7rem;
  margin-right: 0.5rem;
}

.formFormat5 {
  text-align: right;
  width: 7.2rem;
  margin-right: 0.5rem;
}

.formFormat {
  text-align: right;
  width: 7.5rem;
  margin-right: 0.5rem;
}

.formFormat7 {
  text-align: right;
  width: 7.6rem;
  margin-right: 0.5rem;
}

.formFormat8 {
  text-align: right;
  width: 7.8rem;
  margin-right: 0.5rem;
}

.formFormat10 {
  text-align: right;
  width: 8rem;
  margin-right: 0.5rem;
}

.formFormat21 {
  text-align: right;
  width: 8.3rem;
  margin-right: 0.5rem;
}

.formFormat12 {
  text-align: right;
  width: 8.5rem;
  margin-right: 0.5rem;
}

.formFormat16 {
  text-align: right;
  width: 8.8rem;
  margin-right: 0.5rem;
}

.formFormat11 {
  text-align: right;
  width: 9rem;
  margin-right: 0.5rem;
}

.formFormat23 {
  text-align: right;
  width: 9.5rem;
  margin-right: 0.5rem;
}

.formFormat6 {
  text-align: right;
  width: 10rem;
  margin-right: 0.5rem;
}

.formFormat15 {
  text-align: right;
  width: 11rem;
  margin-right: 0.5rem;
}

.formFormat13 {
  text-align: right;
  width: 11.5rem;
  margin-right: 0.5rem;
}

.formFormat20 {
  text-align: right;
  width: 13rem;
  margin-right: 0.5rem;
}

.formFormat9 {
  text-align: right;
  width: 13.4rem;
  margin-right: 0.5rem;
}

.formFormat18 {
  text-align: right;
  width: 16rem;
  margin-right: 0.5rem;
}

.textRight {
  text-align: right;
}

/* CSS margin-top */

.mtop4rem {
  margin-top: -4rem;
}

.mtop3rem {
  margin-top: -3rem;
}

.mtop26rem {
  margin-top: -2.6rem;
}

.mtop25rem {
  margin-top: -2.5rem;
}

.mtop23rem {
  margin-top: -2.3rem;
}

.mtop2rem {
  margin-top: -2rem;
}

.mtop18rem {
  margin-top: -1.8rem;
}

.mtop17rem {
  margin-top: -1.7rem;
}

.mtop15rem {
  margin-top: -1.5rem;
}

.mtop14rem {
  margin-top: -1.4rem;
}

.mtop13rem {
  margin-top: -1.3rem;
}

.mtop12rem {
  margin-top: -1.2rem;
}

.mtop1rem {
  margin-top: -1rem;
}

.mtop09rem {
  margin-top: -0.9rem;
}

.mtop08rem {
  margin-top: -0.8rem;
}

.mtop07rem {
  margin-top: -0.7rem;
}

.mtop06rem {
  margin-top: -0.6rem;
}

.mtop05rem {
  margin-top: -0.5rem;
}

.mtop04rem {
  margin-top: -0.4rem;
}

.mtop03rem {
  margin-top: -0.3rem;
}

.mtop02rem {
  margin-top: -0.2rem;
}

.mtop01rem {
  margin-top: -0.1rem;
}

.mt01rem {
  margin-top: 0.1rem;
}

.mt015rem {
  margin-top: 0.15rem;
}

.mt02rem {
  margin-top: 0.2rem;
}

.mt025rem {
  margin-top: 0.25rem;
}

.mt03rem {
  margin-top: 0.3rem;
}

.mt035rem {
  margin-top: 0.35rem;
}

.mt04rem {
  margin-top: 0.4rem;
}

.mt045rem {
  margin-top: 0.45rem;
}

.mt05rem {
  margin-top: 0.5rem;
}

.mt06rem {
  margin-top: 0.6rem;
}

.mt07rem {
  margin-top: 0.7rem;
}

.mt08rem {
  margin-top: 0.8rem;
}

.mt09rem {
  margin-top: 0.9rem;
}

.mt1rem {
  margin-top: 1rem;
}

.mt12rem {
  margin-top: 1.2rem;
}

.mt13rem {
  margin-top: 1.3rem;
}

.mt14rem {
  margin-top: 1.4rem;
}

.mt15rem {
  margin-top: 1.5rem;
}

.mt17rem {
  margin-top: 1.7rem;
}

.mt2rem {
  margin-top: 2rem;
}

.mt3rem {
  margin-top: 3rem;
}

.mt4rem {
  margin-top: 4rem;
}

.mt5rem {
  margin-top: 5rem;
}


/* CSS margin-bottom */

.mbottom6rem {
  margin-bottom: -6rem;
}

.mbottom3rem {
  margin-bottom: -3rem;
}

.mbottom25rem {
  margin-bottom: -2.5rem;
}

.mbottom2rem {
  margin-bottom: -2rem;
}

.mbottom17rem {
  margin-bottom: -1.7rem;
}

.mbottom15rem {
  margin-bottom: -1.5rem;
}

.mbottom14rem {
  margin-bottom: -1.4rem;
}

.mbottom13rem {
  margin-bottom: -1.3rem;
}

.mbottom12rem {
  margin-bottom: -1.2rem;
}

.mbottom1rem {
  margin-bottom: -1rem;
}

.mbottom09rem {
  margin-bottom: -0.9rem;
}

.mbottom08rem {
  margin-bottom: -0.8rem;
}

.mbottom07rem {
  margin-bottom: -0.7rem;
}

.mbottom06rem {
  margin-bottom: -0.6rem;
}

.mbottom05rem {
  margin-bottom: -0.5rem;
}

.mbottom045rem {
  margin-bottom: -0.45rem;
}

.mbottom04rem {
  margin-bottom: -0.4rem;
}

.mbottom03rem {
  margin-bottom: -0.3rem;
}

.mbottom02rem {
  margin-bottom: -0.2rem;
}

.mbottom01rem {
  margin-bottom: -0.1rem;
}

.mbottom0rem {
  margin-bottom: 0rem;
}

.mb01rem {
  margin-bottom: 0.1rem;
}

.mb015rem {
  margin-bottom: 0.15rem;
}

.mb02rem {
  margin-bottom: 0.2rem;
}

.mb03rem {
  margin-bottom: 0.3rem;
}

.mb04rem {
  margin-bottom: 0.4rem;
}

.mb05rem {
  margin-bottom: 0.5rem;
}

.mb06rem {
  margin-bottom: 0.6rem;
}

.mb07rem {
  margin-bottom: 0.7rem;
}

.mb08rem {
  margin-bottom: 0.8rem;
}

.mb1rem {
  margin-bottom: 1rem;
}

.mb11rem {
  margin-bottom: 1.1rem;
}

.mb12rem {
  margin-bottom: 1.2rem;
}

.mb13rem {
  margin-bottom: 1.3rem;
}

.mb14rem {
  margin-bottom: 1.4rem;
}

.mb15rem {
  margin-bottom: 1.5rem;
}

.mb17rem {
  margin-bottom: 1.7rem;
}

.mb18rem {
  margin-bottom: 1.8rem;
}

.mb2rem {
  margin-bottom: 2rem;
}

.mb22rem {
  margin-bottom: 2.2rem;
}

.mb3rem {
  margin-bottom: 3rem;
}

/* CSS margin-right */

.mright25rem {
  margin-right: -2.5rem;
}

.mright2rem {
  margin-right: -2rem;
}

.mright15rem {
  margin-right: -1.5rem;
}

.mright12rem {
  margin-right: -1.2rem;
}

.mright11rem {
  margin-right: -1.1rem;
}

.mright1rem {
  margin-right: -1rem;
}

.mright09rem {
  margin-right: -0.9rem;
}

.mright08rem {
  margin-right: -0.8rem;
}

.mright07rem {
  margin-right: -0.7rem;
}

.mright06rem {
  margin-right: -0.6rem;
}

.mright05rem {
  margin-right: -0.5rem;
}

.mright045rem {
  margin-right: -0.45rem;
}

.mright04rem {
  margin-right: -0.4rem;
}

.mright035rem {
  margin-right: -0.35rem;
}

.mright03rem {
  margin-right: -0.3rem;
}

.mright02rem {
  margin-right: -0.2rem;
}

.mright01rem {
  margin-right: -0.1rem;
}

.mr015rem {
  margin-right: 0.15rem;
}

.mr01rem {
  margin-right: 0.1rem;
}

.mr02rem {
  margin-right: 0.2rem;
}

.mr03rem {
  margin-right: 0.3rem;
}

.mr04rem {
  margin-right: 0.4rem;
}

.mr05rem {
  margin-right: 0.5rem;
}

.mr06rem {
  margin-right: 0.6rem;
}

.mr07rem {
  margin-right: 0.7rem;
}

.mr08rem {
  margin-right: 0.8rem;
}

.mr09rem {
  margin-right: 0.9rem;
}

.mr1rem {
  margin-right: 1rem;
}

.mr13rem {
  margin-right: 1.3rem;
}

.mr15rem {
  margin-right: 1.5rem;
}

.mr2rem {
  margin-right: 2rem;
}

.mr3rem {
  margin-right: 3rem;
}

.mr5rem {
  margin-right: 5rem;
}

/* CSS margin-left */

.mleft23rem {
  margin-left: -2.35rem;
}

.mleft2rem {
  margin-left: -2rem !important;
}

.mleft15rem {
  margin-left: -1.5rem;
}

.mleft13rem {
  margin-left: -1.3rem;
}

.mleft12rem {
  margin-left: -1.2rem;
}

.mleft1rem {
  margin-left: -1rem;
}

.mleft09rem {
  margin-left: -0.9rem;
}

.mleft08rem {
  margin-left: -0.8rem;
}

.mleft07rem {
  margin-left: -0.7rem;
}

.mleft06rem {
  margin-left: -0.6rem;
}

.mleft05rem {
  margin-left: -0.5rem;
}

.mleft04rem {
  margin-left: -0.4rem;
}

.mleft02rem {
  margin-left: -0.2rem;
}

.ml015rem {
  margin-left: 0.15rem;
}

.ml01rem {
  margin-left: 0.1rem;
}

.ml02rem {
  margin-left: 0.2rem;
}

.ml03rem {
  margin-left: 0.3rem;
}

.ml04rem {
  margin-left: 0.4rem;
}

.ml05rem {
  margin-left: 0.5rem;
}

.ml08rem {
  margin-left: 0.8rem;
}

.ml09rem {
  margin-left: 0.9rem;
}

.ml1rem {
  margin-left: 1rem;
}

.ml12rem {
  margin-left: 1.2rem;
}

.ml13rem {
  margin-left: 1.3rem;
}

.ml15rem {
  margin-left: 1.5rem;
}

.ml158rem {
  margin-left: 1.58rem;
}

.ml18rem {
  margin-left: 1.8rem;
}

.ml2rem {
  margin-left: 2rem;
}

.ml22rem {
  margin-left: 2.2rem;
}

.ml23rem {
  margin-left: 2.3rem;
}

.ml23rem {
  margin-left: 2.5rem;
}

.ml3rem {
  margin-left: 3rem;
}

.ml4rem {
  margin-left: 4rem;
}

.search-input-container {
  position: relative;
}

.formInput {
  width: auto;
}

/* CSS Input */

input {
  color: black !important;
}

option {
  color: black !important;
}

.testcolor {
  font-style: italic !important;
}

.note::placeholder {
  font-style: bold;
  opacity: 0.1;
  font-size: 18px;
}

input::placeholder {
  font-style: italic;
  opacity: 0.1;
  color: red;
  font-size: 13px;
}

::-webkit-input-placeholder {
  color: orange;
}

:-moz-placeholder {
  /* Upto Firefox 18, Deprecated in Firefox 19  */
  color: orange;
}

::-moz-placeholder {
  /* Firefox 19+ */
  color: orange;
}

:-ms-input-placeholder {
  color: orange;
}

/* Css of allpage Promotions */
/* Border */
.brd {
  border: 1px solid #c8ced3;
}

.btnShowCollapse {
  background: #efadad;
  color: white;
}

.btnShowCollapse:hover {
  background: #da7c7c;
  color: rgba(255, 255, 255, 0.604);
}

/* Color*/
.red {
  color: red;
}

.underline {
  text-decoration: underline;
}

.underline:hover {
  color: #e2211b;
  cursor: pointer;
}

.focus:hover {
  color: #2A79B0;
  cursor: pointer;
}

.underlineLink {
  text-decoration: underline;
}

.underlineLink:hover {
  color: #2A79B0;
  cursor: pointer;
}

.backgroundRedHeader {
  background-color: #f86c6b;
  color: white;
  cursor: pointer;
  padding: 8px;
  border: 10px;
  text-align: left;
  outline: none;
  font-size: 15px;
}

.backgroundNoneSelectHeader {
  background-color: #c8ced3;
  cursor: pointer;
  padding: 8px;
  border: 10px;
  text-align: left;
  outline: none;
  font-size: 15px;
}

.sticky-wrapper {
  position: relative;
  height: 3rem;
}

.sticky .sticky-inner {
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1;
}

.block-menu.horizontal {

  display: flex;
  width: 100%;
  flex-wrap: wrap;
}

.block-menu.horizontal>div {
  flex-grow: 1;
}

.block-menu.vertical {
  display: flex;
  flex-direction: column;
}

.block-menu.vertical>div.menu-item {
  width: auto;
}

.menu-container .menu-item {
  padding: 20px;
  margin: 10px;
  background-color: red;
  flex-grow: 1;
}

.input-group-text {
  background-color: #e9e9e9 !important;
}

.dragItem:hover {
  cursor: pointer;
}

.btnNext {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 25px;
}

.priority {
  background-color: #fffac4 !important;
}

.confirm {
  background-color: #d4edda !important;
}


.flexContainer {
  display: flex;
  align-items: stretch;
}

.flexContainer>div {
  text-align: center;
}

.width100 {
  width: 100%;
}

.width600px {
  width: 600px;
}

.flexContainer1 {
  display: flex;
  flex-direction: row
}

.colorTab {
  color: rgb(2, 117, 216);
}

.colorActive {
  color: #464a4c;
}

.colorError {
  color: red;
  font-size: small;
  font-style: italic;
}

.pointer:hover {
  cursor: pointer;
}

.fieldError {
  color: red !important;
  border-color: red;
}

.autocomplete-container {
  z-index: 1000;
  position: absolute;
  border-bottom: honeydew;
  border-left: honeydew;
  border-right: honeydew;
  border-top: 1px solid #e6e6e6;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  border-radius: 0 0 2px 2px;
}

.options {
  display: block;
  list-style: none;
  transition: width 0.3s;
  margin: auto;
  position: relative;
  padding: 4px;
  background-color: wheat;
}

.optionsScroll {

  max-height: 255px;
  overflow-y: auto;
  overflow-x: hidden;
}

.options li {
  display: block;
  background: white;
  margin: 1px auto;
  padding: 10px;
  font-size: 1rem;
  width: 100%;
  border-radius: 2px;
}

.options li:hover {
  font-weight: bold;
  color: #00b4cc;
  cursor: pointer;
  transition: 0.3s all;
}

.options li.option-active {
  background: whitesmoke;
  font-size: 1rem;
  color: #00b4cc;
}

.no-options {
  color: white;
}

.no-options1 {
  color: red;
  margin-bottom: -3rem
}

.backgroundInput1 {
  background-color: #FFFEF4;
  color: black;
  cursor: pointer;
  border: none;
  background: none;
  width: 20px;
  height: 20px;
}

input[type="text"].backgroundInput1:hover {
  border: 1px solid black;
}

body {
  background-color: #f4f6fb !important;
}

.numberCircle {
  border-radius: 50%;
  width: 36px;
  height: 36px;
  background: #ffe83280;
  border: 2px solid rgb(0, 0, 0);
  color: rgb(0, 0, 0);
  text-align: center;
  font: 25px Arial, sans-serif;
  padding: 1.5px;
  margin: 1px;
}

.topActivitesAmount {
  background-color: rgb(255 64 26 / 50%);
  color: black;
  margin-bottom: 0.5rem;
  border-bottom: 2px solid black;
}

.listActivites {
  border: 1px solid #797979;
  margin-top: 0.5rem;
}

.topAmount {
  float: right;
  font-weight: bold;
}

.fontSizeLarge {
  font-size: large;
}

.topHeaderActivites {
  font-size: large;
  margin-left: 0.5rem;
  background-color: white;
}

.topHeaderActivite {
  font-size: large;
  background-color: white;
}

.lineheight2 {
  line-height: 15px;
}

.titleStyle {
  width: 6rem;
  margin-right: 20px;
  display: flex;
  justify-content: flex-end;
}

.btnColorNewActivity {
  background-color: white;
  color: #5780ad;
  border: 1px solid #5780ad;
}

.btnColorNewActivity:hover {
  background-color: #385b80;
  color: white;
  border: 1px solid #385b80;

}

.btnColorSelected {
  background-color: #2d9437;
  color: white;
  border: 1px solid #135f1f;
}

.underlineLink2 {
  color: #20a8d8;
}

.underlineLink2:hover {
  color: #2A79B0;
  cursor: pointer;
  text-decoration: underline;
}

.callcenteruserRep:hover {
  color: #043515;
  font-weight: bold !important;
}

.highLightRow {
  background: azure;
}

.hrPrimary {
  margin-top: 20px;
  margin-bottom: 20px;
  border: 0;
  border-top: 1px solid #eee;

}

.noButton {
  background: none;
  border: none;
  outline: none;
}

.noButton::-moz-focus-inner {
  outline: none;
}

.noButton:focus {
  outline: none;
}

.noButton:hover {
  color: black;
}

.aTagColor {
  color: #20a8d8 !important;
  padding: 0;
}

.aTagColor:hover {
  color: #0c4e66 !important;
  cursor: pointer;
}

@media only screen and (max-width: 500px) {
  .responsiveImg {
    width: 100%;
    height: auto !important;
  }
}

.resetFormCheckInput {
  position: unset;
  margin-top: 0;
  margin-left: 0;
  margin-bottom: 0.3rem;
}

.backgroundOptionDisable {
  background-color: #d6d6d6 !important;
}

.wrapper {
  position: relative;
  overflow: auto;
  max-height: 75vh;
}

.stickycol {
  position: sticky;
  z-index: 2;
  background-color: white !important;
  border: 1px solid #c8ced3;
}

.stickyrow {
  position: sticky;
  z-index: 2;
  background-color: white !important;
  border: 1px solid #c8ced3;
}

.stickycolHead {
  position: sticky;
  z-index: 2;
  background-color: #eaeaea;
  border: 1px solid #c8ced3;
}

.stickycolLast {
  border-right: 2px solid #028bfd !important;
}

.fileCargo {
  margin-right: 1rem;
  justify-content: center;
  align-items: center;
}

.fileCargo button {
  margin-right: 5px;
}

.fileUploadCargo {
  white-space: nowrap;
  width: 100px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.profile-avatar {
  width: 150px;
  height: 150px;
}

.update-profile {
  display: flex;
  justify-content: flex-end;
}

.ml-1rem {
  margin-left: 1rem;
}

.align-profile {
  padding-right: 1rem !important;
  padding-left: 1rem !important;
}

/* Css ProfileInfor */
.div-avatar {
  position: relative;
}

.avatar-profile {
  width: 200px;
  height: 200px;
  border: 1px solid #c8ced3 !important;
}

.label-add {
  position: absolute;
  left: 80px;
  width: 200px;
  height: 200px;
  top: 80px;
  font-size: 30px;
}

.label-remove {
  position: absolute;
  left: 0;
  top: 0;
  font-size: 20px;
}

.zIndex10 {
  z-index: 10;
}

/* end */

.customCalendar {
  display: flex;
  width: auto;
  margin: auto;
  overflow: auto;
  padding-bottom: 1rem;
}

.customCalendar div {
  border: 1px solid gainsboro;
  padding: 0.3rem;
  border-radius: 8px;
  margin: 0 0.5rem;
  flex-direction: column;
  display: flex;
  justify-content: space-between;
}

.customCalendar div:hover {
  cursor: pointer;
  border: 1px solid #3671e4;
  box-shadow: 0px 0px 5px #3671e4;
}

.customCalendar p {
  margin: 0;
  text-align: center;
  white-space: nowrap;
}

.dateSelected {
  background-color: #3671e4;
  color: white;
}

.dateSunday {
  color: red;
}

.dateHasVal {
  background-color: #b5ffc8;
}

.chartjs-render-monitor {
  max-height: 300px;
}

.table th,
.table td {
  vertical-align: middle !important;
}

.customBtnLarge {
  padding: 0.6rem;
  min-width: 190px;
  color: #2a3ee9;
  font-weight: bold;
  background-color: white;
  border: none;
  border-radius: 8px;
  box-shadow: rgba(128, 128, 128, 0.36) 0px 6px 3px -2px;
}

.customBtnLarge:hover {
  color: #2a3ee9;
  background-color: white;
}

.customBtnLargeWithNum {
  padding: 0.5rem 1rem;
  min-width: 190px;
  color: #2a3ee9;
  font-weight: bold;
  background-color: white;
  border: none;
  border-radius: 8px;
  box-shadow: rgba(128, 128, 128, 0.36) 0px 6px 3px -2px;
  display: flex;
  align-items: center;
}

.customNumberBtn {
  flex: 1;
  font-size: 32px;
  text-align: left;
  line-height: 1;
}

.customFilterContainer {
  display: flex;
  justify-content: space-between;
  background-color: white;
  padding: 0.5rem;
  align-items: center;
  box-shadow: 0px 5px 5px rgb(206, 206, 206);
  border-radius: 5px;
}

.customAddFiler {
  border-radius: 50px;
  border: dashed 1px black;
  background-color: transparent;
  padding: 0 0.75rem;
  margin-right: 1.5rem !important;
  display: flex;
  align-items: center;
}

.customAddFiler:hover {
  background-color: transparent;
}

.customAddFiler span {
  font-size: 25px;
  margin-right: 0.2rem;
}

.customSettingIcon {
  font-size: 30px;
  align-items: center;
  display: flex;
  margin-right: 0.5rem !important;
  cursor: pointer !important;
}

.customSearch {
  background-color: #f0f2f5 !important;
  border: none !important;
  border-radius: 5px;
}

.AgreementCheck span {
  padding: 0 0 0 5px;
}

.AgreementCheck {
  padding-left: 1.5rem;
}

.AgreementCheck input {
  margin-top: 3px !important;
  width: 18px;
  height: 18px;
}

.btnRemoveImageAvatar {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
  color: #fc1b63;
  font-size: 30px;
  padding: 5px;
  cursor: pointer;
  transition: 0.5s;
}

.noColor {
  color: transparent !important;
  width: 5rem;
  margin-top: 0.5rem;
}

.itemEmployeeList {
  background-color: white;
  margin-top: 0.5rem;
  padding: 0.5rem 1rem;
  cursor: pointer;
}

.itemEmployeeList img {
  pointer-events: none;
}

.itemEmployeeList div div {
  pointer-events: none;
}

.itemEmployeeList:hover {
  background-color: #b2e9ed;
}

.itemEmployeeList:hover>.hover_underline {
  text-decoration: underline;
}

.defaultTabInfo {
  font-size: 16px;
  cursor: pointer;
  padding-bottom: 0.3rem;
}

.defaultTabInfo:hover {
  color: rgb(0, 131, 143);
}

.selectedTabInfo {
  border-bottom: 1px solid rgb(0, 131, 143);
  color: rgb(0, 131, 143);
}

.tableNoBorder {
  border: none !important;
}

.tableNoBorder tr {
  border: none !important;
}

.tableNoBorder td {
  border: none !important;
}

.tabInfoContainer {
  transition-duration: 0.5s;
  background-color: white;
  position: fixed;
  width: -webkit-fill-available;
  /* Mozilla-based browsers will ignore this. */
  width: -moz-available;
  /* WebKit-based browsers will ignore this. */
  margin-right: 1rem;
}

.customModal {
  text-align: center;
  padding: 0.1rem 1rem;
}

.customModal h5 {
  width: 100%;
}

.customModal button {
  position: absolute;
  right: 10px;
  top: 4px;
}

.customModal .modal-title {
  width: 100%;
}

.uploadImageContainer {
  border-radius: 10px;
  overflow: hidden;
  border: 1px solid rgba(0, 0, 0, 0.25);
  max-width: 250px;
  margin: auto;
}

.imageServiceContainer {
  display: flex;
  justify-content: center;
  margin: 0.5rem 1rem;
}

.uploadImageContainerRow {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: 1rem 0;
  position: relative;
}

.btnDeleteFloat {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
  color: #fc1b63;
  font-size: 22px;
  cursor: pointer;
  transition: 0.5s;
}

.btnDeleteFloat:hover {
  transform: scale(1.15);
}

.btnUploadImageService {
  height: 200px;
  width: 100%;
  display: flex;
  align-items: center;
  border-radius: 10px;
  box-shadow: 0px 0px 15px 10px #8888881c;
  position: relative;
  overflow: hidden;
  padding: 0 !important;
}

.blockOrderText span {
  display: block;
  font-size: 0.95rem;
  padding-top: 0.3rem;
}

.btnCameraUploadAch {
  font-size: 28px;
}

.btnCameraUploadAchUnderline {
  width: 60px;
  border: black 2px solid;
  margin: auto;
  padding: 0 !important;
  margin-top: 0.8rem;
  margin-bottom: 0.8rem;
}

.btntitleImgService {
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 0.3rem;
  width: 100%;
  background-color: rgba(56, 56, 56, 0.479);
  color: white;
  height: auto;
}

@media only screen and (max-width: 650px) {
  .btnUploadImageService {
    height: 150px;
  }

  .imageServiceContainer {
    margin: 0.25rem 0.5rem;
    width: 45%;
  }
}

@media only screen and (max-width: 550px) {
  .btnUploadImageService {
    height: 120px;
  }

  .btntitleImgService b {
    font-size: 12px;
  }
}

@media only screen and (max-width: 480px) {
  .btnUploadImageService {
    height: 220px;
  }

  .imageServiceContainer {
    width: 100%;
  }
}

@media only screen and (max-width: 420px) {
  .btnUploadImageService {
    height: 170px;
  }
}

.timesheetsItems {
  cursor: pointer;
  transition-duration: .2s;
}

.timesheetsItems:hover {
  background-color: #dafffb !important;
}

.stickyCol {
  position: -webkit-sticky;
  position: sticky;
  z-index: 1;
  border: 1px solid gray;
}

.stickyColWhite {
  position: -webkit-sticky;
  position: sticky;
  z-index: 1;
  background-color: white;
  border: 1px solid gray;
}

.firstCol {
  width: 45px;
  left: 0px;
}

.secondCol {
  left: 41px;
  border-right: 1px solid rgb(200, 206, 211) !important;
}

.customTableSticky {
  border-spacing: 0 !important;
  border-collapse: separate !important;
  border-bottom: 1px solid rgb(200, 206, 211) !important;
  border-right: 1px solid rgb(200, 206, 211) !important;
}

.customTableSticky th,
.customTableSticky td {
  border-bottom: 0;
  border-right: 0;
  border-top: 1px solid rgb(200, 206, 211) !important;
  border-left: 1px solid rgb(200, 206, 211) !important;
}

.customSettingIconButton {
  background: none;
  border: none;
  padding: 0;
  margin-right: 1rem;
}

.customSettingIconButton:active,
.customSettingIconButton:focus,
.customSettingIconButton:hover {
  border-style: outset !important;
  background: none !important;
  border: none !important;
  outline: none !important;
  box-shadow: none !important;
}

.timesheetsTotalItems {
  display: flex;
}

.timesheetsTotalItems span {
  min-width: 80px;
  text-align: left;
}

.timesheetsConfirmBtn {
  transition-duration: 0.5s;
}

.timesheetsConfirmBtn:hover {
  transform: scale(1.4);
}

.hoverItemTimesheet:hover>td {
  background-color: #f3ffff;
}

.iValidInfomation {
  position: absolute;
  top: 0px;
  right: 4px;
  color: #d50000;
}

.seniorityBtn {
  height: 10px;
  padding: 0.5rem 0.7rem;
  margin: 0;
  line-height: 0;
  border: 0;
  color: white;
  font-size: 18px;
  font-weight: bold;
}

.seniorityBtn:focus,
.seniorityBtn:hover,
.seniorityBtn:active {
  color: white;
}

.redColor {
  color: red !important;
}

.form-control:disabled,
.form-control[readonly],
input.disabled {
  /* cursor: none; */
  background-color: #efefef !important;
  color: black !important;
}

.ellipsis2line {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}


.inputDateMask {
  border: 1px solid #d9d9d9;
  border-top-left-radius: 6px !important;
  border-bottom-left-radius: 6px !important;
  border-top-right-radius: 6px !important;
  border-bottom-right-radius: 6px !important;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  width: 8rem !important;
  transition-duration: 0.2s;
}

.inputDateMask:focus {
  outline: none !important;
  border: 1px solid #719ECE;
}

.inputDateMask:hover {
  outline: none !important;
  border: 1px solid #719ECE;
}

.inputDateMask.invalidate {
  border: 1px solid red !important;
}

.inputDateMask[value=""],
.inputDateMask[value="__/__/____"] {
  color: rgb(202, 202, 202) !important;
}

.btnCloseUploadACHCheck {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
  color: #fc1b63;
  font-size: 30px;
  padding: 5px;
  cursor: pointer;
  transition: 0.5s;
}

.btnCloseUploadACHCheck:hover {
  transform: scale(1.1);

}

.btnColorFilterOpen:hover,
.btnColorFilterOpen:active,
.btnColorFilterOpen:focus {
  border: none;
  box-shadow: none;
  background-color: transparent;
  color: #850808;
}

.btnColorFilterOpen {
  background-color: transparent;
  color: #350202;
  border: none;
  font-size: 1.3rem;
  border-radius: 8px;
  padding: 0 0.5rem;
  text-align: center;
  justify-content: center;
  display: flex;
  animation: rotating 5s linear infinite;
}

@keyframes rotating {
  from {
    -ms-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  to {
    -ms-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}