@font-face {
  font-family: "Platypi-bold";
  src: local("Platypi-bold"),
    url("./assets/fonts/Platypi-Bold.ttf") format("truetype");
}

@font-face {
  font-family: "Platypi";
  src: local("Platypi"),
    url("./assets/fonts/Platypi-Regular.ttf") format("truetype");
}

body {
  font-family: "Platypi";
}

.nowrap {
  white-space: nowrap !important;
}

.navbar span.d-md-down-none {
  cursor: pointer;
  transition-duration: 1s;
}

.nav-tabs .nav-item span {
  color: rgb(72, 72, 72);
}

.nav-tabs .nav-item .active span {
  color: rgb(0, 131, 143);
}

/* .modal-content {
  border-radius: 0.5rem !important;
  box-shadow: 0px 5px 5px #888 !important;
}

.modal-backdrop.show {
  opacity: 0.2 !important;
} */

/* CSS @media */

@media (min-width: 992px) {
  .ck.ck-reset.ck-editor.ck-rounded-corners {
    min-width: 545px;
  }

  html:not([dir="rtl"]) .sidebar-minimized .sidebar {
    margin-left: -230px !important;
  }
}

@media (max-width: 991px) {
  .ck.ck-reset.ck-editor.ck-rounded-corners {
    /* max-width: 360px; */
    min-width: 330px;
  }

  html:not([dir="rtl"]) .sidebar-md-show .sidebar {
    margin-left: 0px !important;
  }

  html:not([dir="rtl"]) .sidebar {
    margin-left: -230px !important;
  }

  html:not([dir="rtl"]) .sidebar-show .sidebar {
    margin-left: 0 !important;
  }
}

@media (max-width: 991px) {
  .ck.ck-reset.ck-editor.ck-rounded-corners {
    /* max-width: 360px; */
    min-width: 330px;
  }

  .app-header .navbar-brand {
    position: inherit;
    top: auto;
    left: auto;
  }
}

@media (max-width: 375px) {
  .ck.ck-reset.ck-editor.ck-rounded-corners {
    min-width: 280px;
  }
}

@media (max-width: 375px) {
  .ck.ck-reset.ck-editor.ck-rounded-corners {
    min-width: 280px;
  }
}

@media (max-width: 320px) {
  .ck.ck-reset.ck-editor.ck-rounded-corners {
    min-width: 250px;
  }
}

@media (max-width: 280px) {
  .ck.ck-reset.ck-editor.ck-rounded-corners {
    min-width: 200px;
  }
}

/* CSS sidebar */
.sidebar .nav-link .nav-icon {
  color: white !important;
}

.form-validate-error {
  color: red
}

.sidebar .nav-link {
  color: white !important;
}

.sidebar .nav-link:hover {
  background-color: #f0f2f5 !important;
}

.sidebar .nav-link .nav-icon:hover {
  color: white !important;
}

.nav-item .nav-link .badge {
  background-color: #ffff !important;
}

.breadcrumb {
  margin-bottom: 10px !important;
}

.sidebar .nav-link.active {
  background-color: transparent !important;
}

/* CSS Of .dropdown-item */

.navbar-nav .dropdown-item {
  color: black !important;
  transition: .3s !important;
}

.navbar-nav .dropdown-item:hover {
  background-color: #c5c5c5;
}

.navbar-nav .dropdown-item i {
  color: black;
}

.app-header {
  height: 55px !important;
}

.main .container-fluid {
  padding: 0.65rem !important;
  min-height: 100vh;
}

.scrollbar-container {
  height: 100% !important;
}

/* .modal-header {
  padding: 0.5rem 1rem !important;
} */

/* CSS Of Card */

.card {
  margin-bottom: .5rem !important;
  border-radius: 15px !important;
}

.card-body {
  padding: .25rem !important;
}

.card-header {
  padding: 0.5rem 0.75rem !important;
  background-color: #f58634 !important;
}

.card-header>h5 {
  color: white;
}

.fa {
  margin-right: 2px !important;
}

/* CSS Of Modal */

/* .full_modal-dialog {
  width: 98% !important;
  height: 92% !important;
  min-width: 98% !important;
  min-height: 92% !important;
  max-width: 98% !important;
  max-height: 92% !important;
  padding: 0 !important;
}

.full_modal-content {
  height: 99% !important;
  min-height: 99% !important;
  max-height: 99% !important;
} */


.title-roles {
  display: flex;
  justify-content: space-between;
}


/* CSS margin */

.mr-1rem {
  margin-right: 1rem;
}

.ml-1rem {
  margin-left: 1rem;
}

.mt-1rem {
  margin-top: 1rem;
}

.mb-1rem {
  margin-bottom: 1rem;
}

.font-size-10 {
  font-size: 10px !important;
}

.width-auto {
  width: auto;
}

.suggestion-item {
  padding: 8px;
  text-align: left;
  background-color: #fff;
  cursor: pointer;
}

.suggestion-item--active {
  background-color: #fafafa;
}

.datetime {
  height: calc(1.5em + 0.75rem + 2px);
  border: 1px solid #e4e7ea;
  border-radius: 0.25rem;
  padding: 0.375rem 0.75rem;
}

.report-td {
  text-align: center;
  vertical-align: initial;
}

.text-left {
  text-align: left !important;
}

.text-align-end {
  text-align: end;
}

.color-red {
  color: red;
}

.color-blue {
  color: blue;
}

.color-grey {
  color: grey;
}

.report-commission>th,
.report-commission>td {
  vertical-align: middle !important;
}

.color-table-print>tr {
  background-color: #135f1f
}

.fit-img {
  object-fit: cover;
  height: 40px !important;
  width: 40px;
}

.default-Footer {
  z-index: 100;
  bottom: 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
  background-color: #fff;
  padding: 0.2rem 0.65rem !important;
}

.dark_theme .default-Footer {
  z-index: 100;
  bottom: 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
  background-color: #5f5f5f !important;
  padding: 0.2rem 0.65rem !important;
}

.t-overflow {
  white-space: nowrap;
  width: 100%;
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
}

.textColor {
  color: #fff;
  text-decoration: none;
}

.textColor:hover {
  cursor: pointer;
  color: #fff;
}

.prdShadow {
  width: 100%;
  height: 100%;
  transition: 0.2s;
}

.prdShadow:hover {
  margin-left: 5px;
  margin-right: -5px;
  margin-bottom: -5px;
  height: 100%;
  box-shadow: 2px 2px 3px 3px #b3b3b3;
}

.headerTable {
  position: -webkit-sticky;
  position: sticky;
  top: 20px;
}


.btnColorOpen {
  background-color: white;
  color: #350202;
  border: 1px solid #350202;
}

.btnColorOpen:hover {
  background-color: #700f0e;
  color: white;
  border: 1px solid #350202;
}

.btnColorAdd:hover {
  background-color: #2d9437;
  color: white;
  border: 1px solid #135f1f;
}

.btnColorAdd {
  background-color: white;
  color: #043515;
  border: 1px solid #135f1f;
}

.card-footer {
  padding: 0.75rem 0.75rem !important;
  margin: 0 !important;
  background-color: #f0f3f5 !important;
  border-top: 1px solid #c8ced3 !important;
}

.notification {
  cursor: pointer;
  background-color: transparent;
  color: #ff8080;
  text-decoration: none;
  padding: 10px 15px;
  position: relative;
  display: inline-block;
  border-radius: 50%;
}

.notification:hover {
  background: transparent;
  color: red
}

.notification .badge {
  position: absolute;
  top: 5px;
  right: 5px;
  padding: 3px 5px;
  border-radius: 50%;
  background: blue;
  opacity: 0.9;
  color: white;
}

.badgeGoal {
  position: absolute;
  top: -30px;
  padding: 3px 5px;
  border-radius: 50%;
  background: white;
  opacity: 0.9;
  color: red;
  border: red solid 1px;
  font-size: 10px;
}

.form-control:focus {
  border-color: #dfe1e5 !important;
  border-radius: 0.25rem !important;
  box-shadow: 0 0 0 0.2rem rgb(254, 254, 255) !important;
}

.form-inputs:focus {
  border-color: #dfe1e5 !important;
  border-radius: 0.25rem !important;
  box-shadow: 0 0 0 0.2rem rgb(254, 254, 255) !important;
  border-top-left-radius: 25px !important;
  border-bottom-left-radius: 25px !important;
  border-top-right-radius: 25px !important;
  border-bottom-right-radius: 25px !important;
}

.form-control {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  color: #5c6873;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #e4e7ea;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out !important;
  border-radius: 0.25rem;
}

.form-control:disabled,
.form-control[readonly] {
  background-color: #d6dbde;
  opacity: 1 !important;
}

.input-group>.form-control:not(:first-child),
.input-group>.custom-select:not(:first-child) {
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}

.nav .nav-dropdown .nav-dropdown-items .nav-item {
  padding-left: 10px;
}

/* .nav .nav-dropdown .nav-dropdown-items {
  padding-top: 10px;
} */

.nav-item .nav-link .badge {
  background-color: rgba(0, 0, 0, 1) !important;
}

.Toastify__progress-bar--error {
  opacity: 0 !important;
}

.Toastify__progress-bar {
  opacity: 0 !important;
}

.Toastify__progress-bar--animated .Toastify__progress-bar--success {
  opacity: 0 !important;
}

.Toastify__progress-bar--success {
  opacity: 0 !important;
}

.scheduler_event {
  font-size: 15px !important;
  font-weight: bold !important;
}

.table th {
  vertical-align: middle !important;
  padding: 0.5rem !important;
}

.table td {
  padding: 5px !important;
  vertical-align: middle !important;
  color: black;
}

#table td {
  padding: 5px !important;
}

.sidebar {
  background: none !important;
}

.card-header:first-child {
  border-radius: 15px !important;
  border-bottom-left-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.app-body {
  margin-top: 55px;
}

.app-body .sidebar {
  flex: 0 0 230px !important;
  box-shadow: 0 0 20px 5px rgba(5, 23, 34, 0.05);
}

.sidebar .sidebar-nav {
  width: 230px !important;
  background-image: linear-gradient(45deg, #1a7cbc 30%, #f07521) !important;
}

.dark_theme .sidebar .sidebar-nav {
  width: 230px !important;
  background-image: linear-gradient(45deg, #125079 30%, #9e4c15) !important;
}

.sidebar .nav {
  width: 200px !important;
  margin-top: 0 !important;
}

.lds-ring {
  display: inline-block;
  position: relative;
  width: 35px;
  height: 35px;
}

.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 35px;
  height: 35px;
  border: 4px solid #20a8d8;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #20a8d8 transparent transparent transparent;
}

.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}

.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}

.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}

@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

input[type="radio"],
input[type="checkbox"] {
  transform: scale(1.3);
}

.ant-select,
.ant-select-selector {
  height: 100% !important;
}

.ant-select-selection-item[title="--- select ---"] {
  color: gray !important;
}

.dark_theme .ant-select-selection-item[title="--- select ---"] {
  color: rgb(199, 199, 199) !important;
}

textarea,
textarea:hover,
textarea:focus {
  color: black;
}

.dashboard-bg {
  position: relative;
  max-width: 350px;
  margin: auto;
}

.dashboard-bg img {
  width: 350px;
}

.dashboard-item-bg {
  position: absolute;
  left: 15px;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
}

.dashboard-item-bg img {
  width: 50px;
}

.dashboard-item-bg>div {
  margin-left: 1rem;
}

.dashboard-item-bg>div>p:last-child {
  margin: 0;
  font-size: 27px;
  font-weight: bold;
  color: white;
}

.dashboard-item-bg>div>p:first-child {
  margin: 0;
  font-size: 27px;
  font-weight: bold;
  color: white;
}

.btn-close,
.btn-close:focus,
.btn-close:active {
  border: none;
  outline: none;
}

.btn-close:after {
  display: inline-block;
  content: "\00d7";
  position: relative;
  font-size: 30px;
  line-height: 0.5;
  /* This will render the 'X' */
}

.input-group-text {
  color: black !important;
}

.input-group>span {
  color: black !important;
}

textarea {
  color: black !important;
}

.green-backgr {
  background-color: green !important;
}

.green-backgr:hover {
  background-color: rgb(0, 100, 0) !important;
}

.red-backgr {
  background-color: #690505 !important;
}

.red-backgr:hover {
  background-color: red !important;
}

.page-item .visually-hidden {
  display: none;
}

.page-item>button>span:first-child {
  margin-top: -2px;
}

.dark_theme,
.dark_theme .app-header,
.dark_theme .card,
.dark_theme .app-footer,
.dark_theme .pagination .page-link {
  background-color: #444444 !important;
}

.dark_theme p,
.dark_theme span,
.dark_theme td,
.dark_theme th {
  color: white !important;
}

.dark_theme hr {
  border-top: 1px solid rgba(255, 255, 255, 0.5) !important;
}

.dark_theme .table-bordered th,
.dark_theme .table-bordered td,
.dark_theme .card {
  border: 1px solid #7e7e7e !important;
}

.dark_theme .sidebar .sidebar-nav {
  background-color: #003450 !important;
}

.dark_theme .card-header,
.dark_theme .sidebar .nav-link.active,
.dark_theme .sidebar .nav-link:hover,
.dark_theme .sidebar .nav-dropdown-items .nav-link.active,
.dark_theme .sidebar .nav-dropdown-items .nav-link:hover {
  background-color: rgba(255, 255, 255, 0.2) !important;
}

.dark_theme thead,
.dark_theme input,
.dark_theme textarea,
.dark_theme .ant-select-selector,
.dark_theme .dashboard-container {
  background-color: #5f5f5f !important;
  color: white !important;
}

.dashboard-container {
  background-color: white;
  border-radius: 20px;
  padding-top: 1rem;
  padding-bottom: 1rem;
  box-shadow: 0 0 20px 5px rgba(5, 23, 34, 0.05);
}

.dark_theme img {
  filter: drop-shadow(-1px 1px 0px #999) !important;
}

.btn,
.collapse,
.collapsing,
.fade {
  transition: .3s !important;
}

.fa.fa-chevron-right::before {
  display: inline-block;
  transition: .3s !important;
}

.rotate90::before {
  transform: rotate(90deg) translateY(2px) translateX(2px);
  display: inline-block;
  transition: .3s !important;
}

.login-container.dark {
  background-color: #444444 !important;
}

.login-container.dark .card {
  background-color: #646464 !important;
}

.login-container.dark p,
.login-container.dark span,
.login-container.dark h1,
.login-container.dark label {
  color: white !important;
}

.login-container.dark input {
  background-color: #5f5f5f !important;
  color: white !important;
}

.login-container.dark .input-group-text {
  background-color: transparent !important;
  color: white !important;
}

.swal2-cancel {
  margin-left: 1rem !important;
}

.colorHeaderSearch {
  background-color: #f4f6fb;
}

.dark_theme .colorHeaderSearch,
.dark_theme .input-group-text {
  background-color: #363636 !important;
}

.switch {
  position: relative !important;
  display: inline-block !important;
  width: 50px !important;
  height: 25px !important;
  margin-bottom: 0 !important;
  margin-top: 4px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 19px;
  width: 19px;
  left: 3px;
  bottom: 3px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked+.slider {
  background-color: #2196F3;
}

input:focus+.slider {
  box-shadow: 0 0 1px #2196F3;
}

input:checked+.slider:before {
  -webkit-transform: translateX(24px);
  -ms-transform: translateX(24px);
  transform: translateX(24px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.btnFormColorAddSmall:not(.disabled):hover {
  border: none;
  background-color: transparent;
  color: rgb(0, 179, 0) !important;
}

.btnFormColorAddSmall {
  display: flex !important;
  align-items: center !important;
  background-color: transparent !important;
  color: green !important;
  font-size: 1.2rem !important;
  border: none !important;
  padding: 0 !important;
  transition-duration: 0.3s;
}

.btnFormColorAddSmall:focus,
.btnFormColorAddSmall:active {
  outline: 0 !important;
  border: none !important;
  box-shadow: none !important;
  background-color: transparent;
}

.btnFormColorAddSmall.disabled {
  background-color: transparent !important;
  color: #4a7257 !important;
  font-size: 1.2rem !important;
  border: none !important;
  padding: 0 !important;
  transition-duration: 0.3s;
}

.btnFormColorDeleteSmall:not(.disabled):hover {
  border: none;
  background-color: transparent;
  color: red !important;
}

.btnFormColorDeleteSmall {
  display: flex !important;
  align-items: center !important;
  background-color: transparent !important;
  color: #690505 !important;
  font-size: 1.2rem !important;
  border: none !important;
  padding: 0 !important;
  transition-duration: 0.3s;
}

.btnFormColorDeleteSmall:focus,
.btnFormColorDeleteSmall:active {
  outline: 0 !important;
  border: none !important;
  box-shadow: none !important;
  background-color: transparent;
}

.btnFormColorDeleteSmall.disabled {
  background-color: transparent !important;
  color: #724a4a !important;
  font-size: 1.2rem !important;
  border: none !important;
  padding: 0 !important;
  transition-duration: 0.3s;
}

.btnFormColorEditSmall:not(.disabled):hover {
  border: none;
  background-color: transparent;
  color: #0182c6 !important;
}

.btnFormColorEditSmall {
  display: flex !important;
  align-items: center !important;
  background-color: transparent !important;
  color: #00a6ff !important;
  font-size: 1.2rem !important;
  border: none !important;
  padding: 0 !important;
  transition-duration: 0.3s;
}

.btnFormColorEditSmall:focus,
.btnFormColorEditSmall:active {
  outline: 0 !important;
  border: none !important;
  box-shadow: none !important;
  background-color: transparent;
}

.btnFormColorEditSmall.disabled {
  background-color: transparent !important;
  color: #3e718b !important;
  font-size: 1.2rem !important;
  border: none !important;
  padding: 0 !important;
  transition-duration: 0.3s;
}

::-webkit-scrollbar {
  width: 5px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888888a2;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #888;
}

.dropdown-menu {
  display: block !important;
  animation-duration: 0.3s;
  animation-fill-mode: both;
  animation-name: fadeOutUpDd;
  inset: 0px 0px auto auto !important;
  top: 100% !important;
  transform: none !important;
}

.dropdown-menu.show {
  animation-name: fadeInDownDd;
}

@-webkit-keyframes fadeInDownDd {
  0% {
    visibility: hidden;
    opacity: 0;
  }

  100% {
    visibility: visible;
    opacity: 1;
  }
}

@keyframes fadeInDownDd {
  0% {
    visibility: hidden;
    opacity: 0;
  }

  100% {
    visibility: visible;
    opacity: 1;
  }
}

@-webkit-keyframes fadeOutUpDd {
  0% {
    visibility: visible;
    opacity: 1;
    -webkit-transform: translateX(20px);
    transform: translateX(20px);
  }

  100% {
    visibility: hidden;
    opacity: 0;
    -webkit-transform: translateX(20px);
    transform: translateX(20px);
  }
}

@keyframes fadeOutUpDd {
  0% {
    visibility: visible;
    opacity: 1;
    -webkit-transform: translateX(20px);
    transform: translateX(20px);
  }

  100% {
    visibility: hidden;
    opacity: 0;
    -webkit-transform: translateX(20px);
    transform: translateX(20px);
  }
}

.app-header .navbar-brand {
  margin-left: 2.2rem;
}

.whitesmoke {
  background-color: #f9f9f9;
}

.warningrow td {
  color: red !important;
}

.dark_theme .whitesmoke {
  background-color: #f9f9f911;
}

.dark_theme .cusSortLink {
  /* text-decoration: underline; */
  color: white;
  transition-duration: .2s;
  cursor: pointer;
}

.cusSortLink {
  /* text-decoration: underline; */
  color: black;
  cursor: pointer;
  transition-duration: .2s;
}

.cusSortLink:hover {
  /* text-decoration: underline; */
  color: #20a8d8 !important;
}

.dark_theme .cusSortLink:hover {
  text-decoration: underline;
  color: #20a8d8 !important;
}

.card {
  border: none !important;
  box-shadow: 0 0 20px 5px rgba(5, 23, 34, 0.05) !important;
}

.table-bordered th,
.table-bordered td {
  border: 1px solid #e5e5e5 !important;
}

thead th,
thead th span {
  color: #1a7cbc !important;
}

.notification-container .dropdown-item {
  color: rgb(193, 0, 0) !important;
  word-wrap: break-word !important;
  white-space: normal !important;
}

.notification-container .dropdown-menu {
  width: 400px !important;
}

.fa-bell {
  animation: animation-layer-1 2000ms infinite;
}

@keyframes animation-layer-1 {
  0% {
    transform: rotate(0deg);
  }

  25.0% {
    transform: rotate(20deg);
  }

  75.0% {
    transform: rotate(-20deg);
  }

  100.0% {
    transform: rotate(-0deg);
  }
}

.ant-select-disabled,
.ant-select-selection-item-content,
.ant-select-selector,
.ant-select-selection-item {
  color: black;
}

.logo-name {
  font-size: 30px;
  font-weight: bold;
  color: #2e2e2e;
  text-shadow: 0 0 4px #bb9c75;
  white-space: nowrap;
}

.microphone {
  margin: 2rem auto 0 auto;
  font-size: 80px;
  background-color: #e2ad00;
  border-radius: 100%;
  width: 150px;
  height: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  cursor: pointer;
  transition-duration: 500ms;
}

.microphone:hover {
  border: 3px solid white;
}

.microphone-slash {
  margin: 2rem auto 0 auto;
  font-size: 80px;
  background-color: #00e28b;
  border-radius: 100%;
  width: 150px;
  height: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  cursor: pointer;
  transition-duration: 500ms;
}

.microphone-slash:hover {
  border: 3px solid white;
}

.header-student {
  width: 100%;
  margin: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@media (max-width: 480px) {
  .header-student {
    max-width: 1200px;
    width: 100%;
    margin: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .navbar-brand {
    margin: 0;
  }

  .homework-student-container {
    padding: 0 !important;
  }
}

#draw-container {
  max-height: 75vh;
  overflow-y: scroll;
  max-width: 1000px;
  margin: 0.5rem auto;
  padding-left: 0.5rem;
  scrollbar-width: none;
}

.grammar-image-connect {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.grammar-image-connect .image-container {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.grammar-image-connect .image-container.active img {
  border: 2px solid rgb(239, 78, 136);
}

.grammar-image-connect .dot-connect {
  width: 30px;
  text-align: center;
  font-size: 20px;
  color: white;
}

.grammar-image-connect .image-container img {
  width: 200px;
  height: 16vh;
  cursor: grab;
  object-fit: contain;
  border: 2px solid rgb(255, 255, 255);
  border-radius: 15px;
  background-color: rgba(0, 0, 0, 0.185);
  backdrop-filter: blur(20px);
}


.homework-student-container>.card-body {
  margin: 1rem;
}

@media (max-width: 550px) {
  .grammar-image-connect .image-container img {
    width: 150px;
    height: 100px;
  }

  .answer-container div {
    margin: 0;
  }

  .homework-student-container>.card-body {
    margin: 1rem 0.5rem;
  }
}

.login-student-bg {
  background-image: url("./assets/img/banner2.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height: 100vh;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: -1;
}

.login-student .card {
  background-color: #ffffffc7;
  backdrop-filter: blur(20px);
}

.login-student .main .container-fluid {
  min-height: auto;
}

.login-student .app-header {
  z-index: 500;
}

/* .modal-slide-down {
  animation-name: slide-down;
  animation-duration: 1s;
  animation-fill-mode: forwards;
}

@keyframes slide-down {
  0% {
    transform: translateY(-100%);
    opacity: 0;
  }

  100% {
    transform: translateY(0);
    opacity: 1;
  }
} */

.modal-fullscreen {
  height: 100%;
  width: 100%;
  max-width: 100% !important;
  margin: 0 !important;
  max-height: 100vh;
}

.modal-fullscreen .modal-content {
  height: 100%;
  max-height: 100vh;
}

.modal-homework {
  position: fixed;
  top: 50%;
  left: 50%;
  transition: 800ms cubic-bezier(.41, .95, .19, .93);
  width: 0%;
  height: 0%;
  z-index: 1000;
  opacity: 0;
  background-image: url("./assets/img/background.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: left;
}

.modal-homework.active {
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  opacity: 1;
}

.modal-examination {
  position: fixed;
  top: 50%;
  left: 50%;
  transition: 800ms cubic-bezier(.41, .95, .19, .93);
  width: 0%;
  height: 0%;
  z-index: 1000;
  opacity: 0;
  background-blend-mode: luminosity;
  background-color: #fffbf2;
  background-image: url("./assets/img/exampaper.png");
}

.modal-examination.active {
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  opacity: 1;
}

.homework-student-container {
  max-width: 1200px;
  margin: auto;
  padding: 1rem;
  display: flex;
  height: 90vh;
}

.homework-student-left {
  width: 50%;
  border-radius: 30px;
  background-color: white;
  position: relative;
  box-shadow: 7px 7px rgb(0 0 0 / 10%);
  padding: 1.5rem;
}

.homework-student-left-child {
  height: 80vh;
  overflow-y: hidden;
}

.homework-student-right {
  border-radius: 30px;
  width: 50%;
  background: linear-gradient(to bottom, rgba(255, 255, 255, 0.2)70%, rgba(255, 255, 255, 0.0));
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1.5rem;
  position: relative;
  backdrop-filter: blur(20px);
}

.homework-student-right>img {
  position: absolute;
  top: 5vh;
  width: 100%;
  max-width: 500px;
  padding: 0 1rem;
}

.homework-student-selection {
  border-radius: 30px;
  width: 100%;
  background: linear-gradient(to bottom, rgba(255, 255, 255, 0.2)70%, rgba(255, 255, 255, 0.0));
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1.5rem;
  position: relative;
  backdrop-filter: blur(20px);
}

.homework-student-selection>img {
  position: absolute;
  top: 5vh;
  width: 100%;
  max-width: 1000px;
  padding: 0 1rem;
}

.homework-student-left-fake-letter {
  width: 55px;
  height: 86px;
  background-color: #8dd3f5;
  position: absolute;
  top: -42px;
  left: -17px;
  z-index: 3;
  transform: rotate(45deg);
}

.homework-student-left-fake-letter2 {
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  line-height: 1.42857143;
  color: #333;
  font-size: 13px;
  -webkit-font-smoothing: antialiased;
  font-family: 'Montserrat';
  font-weight: 400;
  box-sizing: border-box;
  width: 50px;
  height: 51px;
  background-color: #e0e0e0;
  position: absolute;
  top: 0px;
  left: 0px;
  border-bottom-right-radius: 25px;
}

.homework-student-left-fake-letter3 {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  position: absolute;
  top: -20px;
  left: 0;
}

.homework-student-left-fake-letter4 {
  width: 45px;
  height: 45px;
  border-radius: 50px;
  background-color: #e54857;
  box-shadow: 3px 3px #ba3548, 6px 6px rgb(0 0 0 / 15%);
}

.homework-student-option {
  justify-content: space-between;
  align-items: center;
  border-radius: 15px;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  cursor: pointer;
  padding: 1.5rem 0.4rem;
  box-shadow: 4px 4px rgba(0, 0, 0, 0.20);
  transition-duration: 100ms;
  width: 100%;
}

.homework-student-option:active {
  background-color: rgb(239, 78, 136);
}

.homework-student-option:active>span {
  color: white !important;
}

.homework-student-option:hover span {
  color: rgb(239, 78, 136);
}

.homework-student-option span {
  font-size: 20px;
  font-weight: bold;
  transition-duration: 100ms;
  color: #606060;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
  text-align: center;
  margin-top: 0.4rem;
}

.homework-student-selection .homework-student-option span {
  font-size: 24px;
  font-weight: bold;
  transition-duration: 100ms;
  color: #606060;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
  text-align: center;
  margin-top: 0.4rem;
}

.homework-student-option>div {
  width: 150px;
  height: 150px;
  border-radius: 20px;
  padding: 0.3rem;
  background-color: rgb(239, 78, 136);
  display: flex;
  align-items: center;
}

.homework-student-selection .homework-student-option>div {
  width: 200px;
  height: 200px;
  border-radius: 20px;
  padding: 0.3rem;
  background-color: rgb(239, 78, 136);
  display: flex;
  align-items: center;
}

.homework-student-option img {
  width: 100%;
  height: auto;
}

.lesson-items {
  border: 2px solid rgb(239, 78, 136);
  border-radius: 15px;
  padding: 1rem;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  transition-duration: 800ms;
}

.lesson-items span {
  transition-duration: 200ms;
}

.lesson-items:hover div:first-child span {
  color: rgb(239, 78, 136);
}

.lesson-items.active {
  background-color: rgb(239, 78, 136);
  color: white !important;
}

.lesson-items.active:hover div:first-child span {
  color: white !important;
}

.items-percent {
  padding: 0.8rem 1rem;
  background-color: #fadd00;
  border-radius: 50px;
  border: 4px solid white;
  color: black;
  width: 110px;
  text-align: center;
}

.dha-lesson-content-background-line-item {
  width: 8%;
  height: calc(100% + 70px);
  background-color: rgba(255, 255, 255, 0.2);
  position: absolute;
  transform: rotate(45deg);
  right: 15%;
  top: -38%;
  pointer-events: none;
}

.homework-container {
  height: 100%;
  width: 100%;
  overflow-y: scroll;
  position: relative;
}

.homework-container .backbtn {
  position: fixed;
  top: 10px;
  left: 10px;
  padding: 0.8rem;
  border-radius: 50px;
  background-color: white;
  margin: 0;
  cursor: pointer;
  transition-duration: 500ms;
}

.homework-container .backbtn:hover {
  color: #00a6ff;
}

.homework-container .backbtn i {
  width: 20px;
  height: 20px;
  font-size: 40px;
  margin: 0 !important;
  display: flex;
  align-items: center;
  justify-content: center;
}

.homework-container .bottom-container {
  display: flex;
  position: fixed;
  bottom: 5px;
  left: 0;
  right: 0;
  justify-content: center;
  align-items: center;
}

.homework-container .bottom-container .number {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 25px;
  border: 7px solid #f8bd34;
  padding: 0.4rem 2rem;
  background-color: white;
}

.homework-container .bottom-container .number span {
  color: #f5ac24;
  font-size: 40px;
  font-weight: bold;
  line-height: 1.3;
}

.homework-container .bottom-container .navigatebtn {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 25px;
  padding: 1rem;
  margin: 0 1.5rem;
  color: white;
  font-size: 22px;
  background-color: #f5ac24;
  cursor: pointer;
  transition-duration: 500ms;
}

.homework-container .examination .navigatebtn {
  padding: 1rem;
  margin: auto;
  border-radius: 25px;
  text-align: center;
  width: 13rem;
  color: white;
  font-size: 22px;
  background-color: #f5ac24;
  cursor: pointer;
  transition-duration: 500ms;
}

.homework-container .examination .navigatebtn:hover {
  background-color: #cf8700;
}

.bottom-container .navigatebtn.disable {
  cursor: not-allowed;
}

.homework-container .bottom-container .navigatebtn:hover {
  background-color: #cf8700;
}

.pronounce-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10vh;

}

.pronounce-container .word {
  display: flex;
  align-items: center;
  border-radius: 30px;
  background-color: rgb(239, 78, 136);
  padding: 1rem 5rem;
  cursor: pointer;
  transition-duration: 500ms;
}

.pronounce-container .word:hover {
  background-color: rgb(212, 44, 105);
}

.pronounce-container .word span {
  font-weight: bold;
  color: white;
  font-size: 40px;
}

.pronounce-container .word i {
  padding-left: 1.5rem;
  color: white;
  font-size: 40px;
}

.bounce {
  animation: bounce 2s infinite;
  -webkit-animation: bounce 2s infinite;
  -moz-animation: bounce 2s infinite;
  -o-animation: bounce 2s infinite;
}

@-webkit-keyframes bounce {

  0%,
  20%,
  50%,
  80%,
  100% {
    -webkit-transform: translateY(0);
  }

  40% {
    -webkit-transform: translateY(-30px);
  }

  60% {
    -webkit-transform: translateY(-15px);
  }
}

@-moz-keyframes bounce {

  0%,
  20%,
  50%,
  80%,
  100% {
    -moz-transform: translateY(0);
  }

  40% {
    -moz-transform: translateY(-30px);
  }

  60% {
    -moz-transform: translateY(-15px);
  }
}

@-o-keyframes bounce {

  0%,
  20%,
  50%,
  80%,
  100% {
    -o-transform: translateY(0);
  }

  40% {
    -o-transform: translateY(-30px);
  }

  60% {
    -o-transform: translateY(-15px);
  }
}

@keyframes bounce {

  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateY(0);
  }

  40% {
    transform: translateY(-30px);
  }

  60% {
    transform: translateY(-15px);
  }
}

.abcd-container {
  max-width: 1400px;
  margin: auto;
  text-align: center;
  margin-top: 1.5rem;
}

.abcd-container .question {
  font-weight: bold;
  font-size: 40px;
  color: white;
}

.abcd-container .answer-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}

.abcd-container .answer-container-connection {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}

.abcd-container .answer-input-container {
  display: grid;
  align-items: center;
  justify-content: center;
  padding: 0 0.5rem;
}

.abcd-container .answer-input-container span {
  font-weight: bold;
  font-size: 30px;
  color: rgb(239, 78, 136);
}

.abcd-container .answer-input-container textarea {
  margin-top: 1rem;
  max-width: 600px;
  font-size: 40px;
}

.abcd-container .answer-abcd {
  margin-top: 2rem;
  margin-left: 1rem;
  margin-right: 1rem;
  width: 500px;
  max-width: 500px;
  font-size: 26px;
  color: black;
  background-color: white;
  border-radius: 30px;
  transition-duration: 0.5s;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 5px 5px 5px #35353554;
}

.abcd-container .answer-connection {
  width: 300px;
  font-size: 24px;
  color: black;
  background-color: white;
  border-radius: 30px;
  transition-duration: 0.5s;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 5px 5px 5px #35353554;
  padding: 0.5rem;
}

.abcd-container .answer-abcd:hover {
  color: rgb(239, 78, 136);
}

.abcd-container .answer-abcd.active {
  color: white;
}

.answer-abcd.active {
  background-color: rgb(239, 78, 136);
  color: white;
}

.answer-abcd.correct {
  background-color: #00c40a;
  color: white;
}

.answer-abcd.active.correct {
  background-color: #00c40a;
  color: white;
}

.answer-container-grab span {
  border: 2px solid rgb(255, 255, 255);
  color: white;
  border-radius: 10px;
  background-color: rgba(0, 0, 0, 0.185);
  backdrop-filter: blur(20px);
  padding: 0.5rem 1rem;
  font-size: 26px;
}

.answer-container-grab {
  display: flex;
  align-items: center;
  cursor: grab;
}

.answer-container-grab div {
  margin-right: 0.5rem;
}

.congratulation {
  margin: auto;
  padding: 5rem 1rem;
  text-align: center;
}

.congratulation img {
  width: 20vw;
  margin: 0.5rem 1rem;
}

.congratulation .text {
  margin-top: 3rem;
}

.congratulation span {
  color: white;
  font-size: 50px;
  font-weight: bold;
}

.congratulation.exam img {
  width: 13vw;
  margin: 0.5rem;
}

.congratulation.exam>div {
  display: flex;
  align-items: start;
  justify-content: center;
}

@media (max-width: 987px) {
  .homework-student-right {
    display: none;
  }

  .homework-student-left {
    width: 100%;
  }

  .homework-student-center {
    width: 0%;
    display: none;
  }

  .homework-student-option img {
    border-radius: 10px;
    background-color: rgb(239, 78, 136);
  }

  .homework-student-option>div {
    width: 80px;
    height: 80px;
  }

  .homework-student-option {
    box-shadow: none;
    margin-top: 0.5rem;
  }

  .abcd-container .answer-abcd {
    margin-top: 1.5rem;
  }
}

.homework-student-center {
  width: 5%;
}

.frame-container {
  display: flex;
}

.frame-drop {
  border-radius: 25px;
  border: 2px dashed rgba(0, 0, 0, 0.479);
  position: relative;
  display: flex;
}

.image-right {
  width: 100px;
  height: 100px;
}

.image-right img {
  width: 100%;
  height: 100%;
  object-fit: scale-down;
}

.frame-drop .drag-container.selected {
  border: 1px dashed red;
}

.frame-drop .drag-container {
  border: 1px dashed transparent;
  height: fit-content;
}

.resizer {
  width: 10px;
  height: 10px;
  background: blue;
  position: absolute;
  right: 0;
  bottom: 0;
  cursor: se-resize;
}

.congratulation .view-result {
  margin-top: 2rem;
  margin-left: auto;
  margin-right: auto;
  width: 500px;
  max-width: 500px;
  font-size: 26px;
  background-color: #00c40a;
  border-radius: 30px;
  transition-duration: 0.5s;
  cursor: pointer;
  display: flex;
  justify-content: center;
  box-shadow: 5px 5px 5px #35353554;
  padding: 0.5rem;
}

.congratulation .view-result span {
  color: white;
}

.congratulation .view-result:hover {
  background-color: rgb(239, 78, 136);
}

@media (max-width: 1400px) {

  .homework-student-left-child {
    height: 70vh;
    overflow-y: hidden;
  }

}

@media (max-width: 768px) {
  .homework-student-container {
    padding: 1rem 0 !important;
  }

  .grammar-image-connect .image-container img {
    width: 200px;
    height: 16vh;
  }

  .abcd-container .answer-connection {
    width: 40%;
    font-size: 16px;
    color: black;
    background-color: white;
    border-radius: 10px;
    transition-duration: 0.5s;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 5px 5px 5px #35353554;
    padding: 0.3rem;
  }

  .homework-student-left-child {
    height: 60vh;
    overflow-y: hidden;
  }

}

.select-student {
  padding: 0.4rem 3rem;
  background-color: white;
  border-radius: 30px;
  font-size: 25px;
  margin-left: 1rem;
  margin-right: 1rem;
  transition-duration: 0.3s;
  cursor: pointer;
}

.select-student:hover {
  background-color: rgb(239, 78, 136);
  color: white;
}

.select-student.active {
  background-color: rgb(239, 78, 136);
  color: white;
}